.intro {
    padding-top: 128px !important;
    top: -80px !important;
    z-index: 1;
    
    @media (min-width: 768px) {
        padding-top: 192px !important;
        top: -112px !important;
    }

    @media (min-width: 1024px) {
        padding-top: 278px !important;
        top: -166px !important;
    }
}