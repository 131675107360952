.how {
    z-index: 2;

    h2 {
        color: var(--color-3);
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__item {
        align-items: flex-start;
        display: flex;
        gap: 24px;

        p {
            margin: 0;
        }
    }

    &__icon {
        font-size: 24px;
        position: relative;
        top: -0.25em;
        
        @media (min-width: 768px) {
            font-size: 32px;
        }
    }

    &__text {
        flex: 1;
    }
}