.portfolio {
    z-index: 4;

    &__grid {
        display: flex;
        flex-wrap: wrap;
        margin: -16px;

        @media (min-width: 768px) {

        }
    }

    &__item {
        border: 16px solid transparent;
        display: block;
        flex: 0 0 50%;
        text-align: center;
        
        @media (min-width: 768px) {
            flex: 0 0 25%;
        }

        &:hover {
            .portfolio__title {
                animation: underline 500ms linear infinite;
                background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 5"><path d="M0,1c2.49983,0,2.49983,3.00007,4.99966,3.00007,2.50017,0,2.50017-3.00007,5.00034-3.00007" fill="none" stroke="%23469F39" stroke-miterlimit="10"/></svg>');
            }
        }
    }

    &__logo {
        display: block;
        transition: transform 250ms ease;

        &:hover {
            transform: scale(0.95);
        }
    }

    &__title {
        background-image: none;
        background-repeat: repeat-x;
        background-position-y: bottom;
        background-size: 10px 5px;
        color: var(--color-4);
        display: inline-block;
        font-size: 12px;
        font-weight: 600;
        text-decoration: none;
        padding-bottom: 3px;
        
        @media (min-width: 768px) {
            font-size: 14px;
        }
    }
}