@charset 'utf-8';

@import '~reset-css/sass/reset';

:root {
    --color-text: #262C30;
    --color-bg: #F6F0E6;

    --color-1: #1C2023;
    --color-2: #F6F0E6;
    --color-3: #FCC9CF;
    --color-4: #469F39;
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

@keyframes underline {
    0% { background-position-x: 0; }
    100% { background-position-x: -10px; }
}

body {
    background-color: var(--color-bg);
    color: var(--color-text);
    font-family: 'Nunito Sans', sans-serif;
    font-feature-settings: 'ss02' 1;
    font-size: 18px;
    line-height: 1.5;

    @media (min-width: 768px) {
        font-size: 22px;
    }

    @media (min-width: 1024px) {
        font-size: 24px;
    }
}

body,
html {
    min-height: 100%;
    width: 100%;
}

html {
    scroll-behavior: initial;
}

p {
    margin: 0 0 1.25em 0;

    &:last-child {
        margin-bottom: 0;
    }
}

h1, h2 {
    font-weight: 600;
    letter-spacing: -0.015em;
}

h1 {
    font-size: 64px;
    font-weight: 900;

    @media (min-width: 768px) {
        font-size: 128px;
    }

    @media (min-width: 1024px) {
        font-size: 160px;
    }
}

h2 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 1em;

    @media (min-width: 768px) {
        font-size: 40px;
    }

    @media (min-width: 1024px) {
        font-size: 48px;
    }
}

img {
    display: block;
    max-width: 100%;
}

.wrapper {
    margin: 0 auto;
    max-width: 1600px;
}

.underline {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 5"><path d="M0,1c2.49983,0,2.49983,3.00007,4.99966,3.00007,2.50017,0,2.50017-3.00007,5.00034-3.00007" fill="none" stroke="%231C2023" stroke-miterlimit="10"/></svg>');
    background-repeat: repeat-x;
    background-position-y: bottom;
    background-size: 10px 5px;
    color: var(--color-text);
    text-decoration: none;
    
    &:hover {
        animation: underline 500ms linear infinite;
    }
}

@import 'components/header';
@import 'components/how';
@import 'components/intro';
@import 'components/portfolio';
@import 'components/questions';
@import 'components/section';